<template>
  <div class="w-100 h-100 overflow-auto">
    <d-remote-monitoring-questionnaires-view
      :id="id"
      @on-create="onCreateHandler"
    />
  </div>
</template>

<script>

export default {
  name: 'RemoteMonitoringQuestionnaireView',
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this.$attrs.id;
  },
  methods: {
    onCreateHandler() {
      this.$router.push('/remote-monitoring/questionnaires/');
    },
  },
};
</script>

<style lang="scss">
.rm-admin-qs-add {
    .col-form-label {
        font-size: 16px;
    }

    .custom-control-label::before {
        top: 1px;
    }
    .custom-control-label::after {
        top: 1px;
    }

    input.error {
      border-color:  $red;
    }

    textarea.error {
      border-color:  $red;
    }
    select.error {
       border-color:  $red;
    }
}
</style>
